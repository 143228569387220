import { combineReducers } from 'redux';
import productListReducer, {
  currentCategory,
  selectedSortBy,
  categoryNoResultsContent,
  seo,
  isValidCategory,
  selectedFilterOptions,
  productListMetadata,
  sizesFiltered,
  visualNav,
  productRecs,
  foundItRelatedCategories
} from './reducers/productListReducer';

export default combineReducers({
  products: productListReducer,
  currentCategory,
  selectedSortBy,
  categoryNoResultsContent,
  sizesFiltered,
  seo,
  isValidCategory,
  selectedFilterOptions,
  productListMetadata,
  visualNav,
  productRecs,
  foundItRelatedCategories
});
